<script>
export default {
  data() {
    return {
      locale: null,
    };
  },
  methods: {
    openHelpCentreInNewTab(event) {
      event.preventDefault();
      window.open(`https://help.laundryheap.com/${this.locale}`, '_blank');
    },
  },
  computed: {
    helpCentreUrl() {
      return `https://help.laundryheap.com/${this.locale}`;
    },
  },
  mounted() {
    const fullLocale = window.locale || 'en';
    this.locale = fullLocale === 'es-419' ? 'es' : fullLocale;
  },
};
</script>

<template>
  <div class="d-flex flex-column align-items-center text-center">
    <img
      :src="require('images/price_bot/chatIcon.svg')"
      :alt="$t('bot.title')"
      class="d-block mb-10"
      height="88"
      width="208"
      loading="lazy"
    />
    <p class="font-24-24 fw-500 mb-8">{{ $t('bot.empty.title') }}</p>
    <p class="font-18-24 dark-grey mb-32">
      {{ $t('bot.empty.subtitle') }}
    </p>

    <p class="font-14-24 dark-grey">
      <span>
        {{ $t('bot.empty.ourHelpCentreText') }}
        <a
          :href="helpCentreUrl"
          class="text-underline primary-blue pricing-bot-empty-state-button"
          @click="openHelpCentreInNewTab"
        >
          {{ $t('bot.empty.ourHelpCentreLink') }}
        </a>
      </span>
    </p>
  </div>
</template>
